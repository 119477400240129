import SeparatedLine2 from "../../assets/images/SeparatedLine2.svg";
import DividerMob2 from "../../assets/images/mobile/DividerMob2.svg";

const SecondLine = () => {
  return (
    <>
      <div className="w-full md:block xs:hidden">
        <img className="w-full" src={SeparatedLine2} alt="Separated Line" />
      </div>

      <div className="w-full md:hidden xs:block">
        <img className="w-full" src={DividerMob2} alt="Separated Line" />
      </div>
    </>
  );
};
export default SecondLine;
